import { ErrorObject } from "@/src/utils/error/error-object";

import style from "./error-try-refresh.module.css";
import RefreshButton from "./refresh-button";

interface ErrorTryRefreshProps {
  error: ErrorObject;
}

/* WARNING: use a serializable object as 'error' if passing the parameter between client and server components */
const ErrorTryRefresh = ({ error }: ErrorTryRefreshProps) => (
  <div className={style.errorContainer}>
    <h1 className={style.errorName}>{error.name}</h1>
    <h2 className={style.errorDescription}>{error.message}</h2>
    <RefreshButton />
  </div>
);

export default ErrorTryRefresh;

"use client";

import { useTranslations } from "next-intl";

import { Button } from "@sikt/sds-button";

import { useRouter } from "@/src/intl/navigation";

import style from "./refresh-button.module.css";

const RefreshButton = () => {
  const t = useTranslations("error");

  const router = useRouter();

  const refresh = () => {
    router.refresh();
  };

  return (
    <Button variant="strong" className={style.refreshButton} onClick={refresh}>
      {t("try-again")}
    </Button>
  );
};

export default RefreshButton;
